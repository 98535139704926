import { useNavigate, useParams } from 'react-router-dom';
import './Footer.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { RequestInitiation } from '../models/RequestInitiation';

export const Footer = () => {
  let navigate = useNavigate(); 
  let { email } = useParams();
  email = email?.toLowerCase();

  const isAdmin = () => {
    let adminCode = localStorage.getItem("adminCode");
    if(adminCode) {
      return true;
    }
  }

  const goToMyRequest = () => {
    if(email) {
      navigate('/requests/' + email + '/view');
    }

    let requestsJson = localStorage.getItem("colorAnalysisRequests");
    if(requestsJson)
    {
      let parsedRequests = JSON.parse(requestsJson) as RequestInitiation[];
      if(parsedRequests.length === 1)
      {
        if(parsedRequests[0].verified) {
          navigate('/requests/' + parsedRequests[0].email + '/view');
        } else {
          navigate('/requests/' + parsedRequests[0].email + '/verify');
        }
      }
      else {
        navigate('/');
      }
    }
    else {
      navigate('/');
    }
  }

  return (
    <div className="footer-basic">
        <footer>
            <div className="social">
              <a href="https://www.tiktok.com/@kaylijarosz?_t=8oUefGdvMGB&_r=1" target="_blank" ><i className="icon bi bi-tiktok"></i></a>
              <a href="https://www.instagram.com/kaylijarosz/" target="_blank" ><i className="icon bi-instagram"></i></a>
              <a href="mailto:kayli@colorsbykayli.com" target="_blank" ><i className="icon bi bi-envelope"></i></a>
              <a href="https://www.pinterest.com/kaylijarosz/" target="_blank" ><i className="icon bi bi-pinterest"></i></a>
              
            </div>
            <div className="centerIt footerInternalLinks">
              <button onClick={(e) => navigate('/') } className="linkTextInside">Home</button><br/>
              <button onClick={(e) => goToMyRequest() } className="linkTextInside">My Color Request</button><br/>
              <button onClick={(e) => navigate('/FAQs') } className="linkTextInside">FAQs</button><br/>
              { isAdmin() && <div>
                  <button onClick={(e) => navigate('/Admin') } className="linkTextInside">Admin</button><br/>
                </div>
              }
            </div>
            <p className="copyright">kayli@colorsbykayli.com</p>
        </footer>
    </div>
  );
};
