import React, { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { Ticket } from '../../models/Ticket';
import { Footer } from '../../components/Footer';
import { KayliColorAPI } from '../../apis/KayliColorAPI';

const RequestInQueue: React.FC = () => {
  let navigate = useNavigate(); 
  let { email } = useParams();
  email = email?.toLowerCase();

  const [ticket, setTicket] = useState<Ticket>();
  useEffect(() => {
    KayliColorAPI.getTicket(email!)
      .then((response)=>{
        setTicket(response.data);
      })
      .catch(function (error) {
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  return (
    <div>
      <div className="homeContainer">
        <div className="otherPageHeader">You're in the queue!</div>

        <div className="homeBody">{`Unfortunately I've got a lot of people waiting to have some help finding out their color season. I'll send you an email when it's almost your turn. Sorry about the wait, and thanks for your patience!`}</div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default RequestInQueue;
