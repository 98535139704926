export enum ColorSeasons {
  CoolSummer="CoolSummer",
  LightSummer="LightSummer",
  SoftSummer="SoftSummer",
  WarmSpring="WarmSpring",
  LightSpring="LightSpring",
  BrightSpring="BrightSpring",
  WarmAutumn="WarmAutumn",
  SoftAutumn="SoftAutumn",
  DeepAutumn="DeepAutumn",
  CoolWinter="CoolWinter",
  BrightWinter="BrightWinter",
  DeepWinter="DeepWinter"
}

export const ColorSeasonsTools = {
  ToPrettyText: function (season: ColorSeasons | undefined, toUpper: boolean): string {
    if(season === ColorSeasons.CoolSummer) { return toUpper ? "COOL SUMMER" : "Cool Summer" }
    else if(season === ColorSeasons.LightSummer) { return toUpper ? "LIGHT SUMMER" : "Light Summer" }
    else if(season === ColorSeasons.SoftSummer) { return toUpper ? "SOFT SUMMER" : "Soft Summer" }
    else if(season === ColorSeasons.WarmSpring) { return toUpper ? "WARM SPRING" : "Warm Spring" }
    else if(season === ColorSeasons.LightSpring) { return toUpper ? "LIGHT SPRING" : "Light Spring" }
    else if(season === ColorSeasons.BrightSpring) { return toUpper ? "BRIGHT SPRING" : "Bright Spring" }
    else if(season === ColorSeasons.WarmAutumn) { return toUpper ? "WARM AUTUMN" : "Warm Autumn" }
    else if(season === ColorSeasons.SoftAutumn) { return toUpper ? "SOFT AUTUMN" : "Soft Autumn" }
    else if(season === ColorSeasons.DeepAutumn) { return toUpper ? "DEEP AUTUMN" : "Deep Autumn" }
    else if(season === ColorSeasons.CoolWinter) { return toUpper ? "COOL WINTER" : "Cool Winter" }
    else if(season === ColorSeasons.BrightWinter) { return toUpper ? "BRIGHT WINTER" : "Bright Winter" }
    else if(season === ColorSeasons.DeepWinter) { return toUpper ? "DEEP WINTER" : "Deep Winter" }
    else { return "Undefined" }
  },
}