import React, { useEffect, useState } from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import DataTable from 'react-data-table-component';
import { Footer } from '../../components/Footer';
import { StatusCount } from '../../models/StatusCount';
import Loading from '../../components/Loading';

function AdminQueueManagement() {
  let navigate = useNavigate(); 

  const [moveToReadyToPay, setMoveToReadyToPay] = useState('0');
  const [moveToImportedEmailed, setMoveToImportedEmailed] = useState('0');
  const [statusCounts, setStatusCounts] = useState<StatusCount[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    reload();
  }, [ ]);

  const reload = () => {
    setLoading(true);
    KayliColorAPI.getQueueManagementData()
      .then((response)=>{
        setStatusCounts(response.data);
      })
      .catch(function (error) {
          setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
          console.log(error.response ? error.response : error.request);
      })
      .finally( () => {
          setLoading(false);
      });
  }

  const moveXTo = (x : string, readyToPay: boolean) => {
    setLoading(true);
    setErrorMessage('');

    let xNumber = Number.parseInt(x);

    if(readyToPay)
    {
      KayliColorAPI.moveXToReadyToPay(xNumber)
        .then((response)=>{
          setErrorMessage(response.data)
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
    }
    else
    {
      KayliColorAPI.moveXToImportedEmailed(xNumber)
        .then((response)=>{
          setErrorMessage(response.data)
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
    }
  }

  const columns = [
    { name: 'Status', selector: (row: StatusCount) => row.status },
    { name: 'Count', selector: (row: StatusCount) => row.count }
  ];

  return (
    <div>
        <h1>Queue Management</h1>
        <DataTable  columns={columns} data={statusCounts} />
        <Loading loading={loading} className=""></Loading>

        <br/>
        <div>Max 25</div>
        <br/>

        <span>
          Move from InQueueUnpaid to ReadyToPay: 
          <input className="enterEmail enterCode enterMoveStatusNumber" onChange = {(e) => setMoveToReadyToPay(e.target.value)}  
                  value = {moveToReadyToPay}
                  maxLength={2}></input>
          <button className="croppingButton" onClick={(e) => moveXTo(moveToReadyToPay, true)}>Send ReadyToPay Emails</button>
        </span>

        <br/><br/>

        <span>
          Move from ImportedVerified to ImportedEmailed: 
          <input className="enterEmail enterCode enterMoveStatusNumber" onChange = {(e) => setMoveToImportedEmailed(e.target.value)}  
                  value = {moveToImportedEmailed}
                  maxLength={2}></input>
          <button className="croppingButton" onClick={(e) => moveXTo(moveToImportedEmailed, false)}>Send Imported Emails</button>
        </span>

        <br/><br/>
        <Loading loading={loading} className=""></Loading>
        <br/>

        { errorMessage && <h2> {errorMessage} </h2> }
        <button className="croppingButton" onClick={(e) => navigate('/admin')}>Back to Admin Screen</button>
        <Footer></Footer>
    </div>
    
    
  );
}

export default AdminQueueManagement;
