import './ColorFramedPortrait.css';

type ColorFramedPortraitProps = {
  path: string;
  hrefBase: string;
  hrefCover: string;
  svgClass? : string;
  labelClass? : string;
  label?: string;
  viewPortHeight: number;
  viewPortWidth: number;
};

export const ColorFramedPortrait = ({
  path,
  hrefBase,
  hrefCover,
  svgClass,
  labelClass,
  label,
  viewPortHeight,
  viewPortWidth
}: ColorFramedPortraitProps) => {

  return (
    <div className="container">
      <div className={labelClass} >{label}</div>
      <svg viewBox={`0 0 ${viewPortWidth} ${viewPortHeight}`} className={svgClass} >
        <mask id="transition">
          <path d={path} fill="white" />
        </mask>
        <image href={hrefCover} className="template" />
        <image href={hrefBase} mask="url(#transition)" className="template" />
      </svg>
    </div>
  );
};
