import React, { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { Ticket } from '../models/Ticket';
import { Footer } from '../components/Footer';
import { KayliColorAPI } from '../apis/KayliColorAPI';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./Faq.css"
import {
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBRow,
    MDBTypography,
  } from "mdb-react-ui-kit";

const Faq: React.FC = () => {
  let navigate = useNavigate(); 
  let { email } = useParams();
  email = email?.toLowerCase();

  const [ticket, setTicket] = useState<Ticket>();
  useEffect(() => {
    KayliColorAPI.getTicket(email!)
      .then((response)=>{
        setTicket(response.data);
      })
      .catch(function (error) {
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  return (
    <div>
      <MDBContainer>
        <section>
            <MDBTypography
            tag="h3"
            className="text-center mb-4 pb-2 text-primary fw-bold"
            >
            <div className="faqHeader">Frequently Asked Questions</div>
            </MDBTypography>
            <p className="text-center mb-5">
            Find the answers for the most frequently asked questions below
            </p>

            <MDBRow className="faqContainer">
            <MDBCol md="6" lg="4" className="mb-4">
                <MDBTypography tag="h6" className="mb-3 text-primary">
                <MDBIcon far icon="paper-plane text-primary pe-2" /> What do "Color Analysis" and "Color Seasons" mean?
                </MDBTypography>
                <p>
                <strong>
                    <u>Great Question!</u>
                </strong>{" "}
                A color season is a collection of colors that have the following features in common: their warmth/coolness, softness/brightness, and light/depth. These colors are grouped into twelve unique categories, four seasons (spring, summer, autumn, winter), and within each season, three subseasons. Each human's unique combination of traits means that one of these twelve seasons will naturally best match their features, enhancing their natural beauty. Color analysis is the act of reviewing your features and how they look with certain colors to determine your best palette!
                </p>
            </MDBCol>
            <MDBCol md="6" lg="4" className="mb-4">
                <MDBTypography tag="h6" className="mb-3 text-primary">
                <MDBIcon fas icon="rocket text-primary pe-2" /> Is it okay if I don't want to be featured on your Tiktok?
                </MDBTypography>
                <p>
                Yes. You can opt in or out of being featured on my Tiktok as an example as part of the submission process. If you say "no, thanks!" I will know you wish to maintain your privacy and I will not share your photos online for any reason. If you say "yes, thank you!" I will have the option to use your photos as an example! I may or may not do so, depending on what examples people are asking to see. If you volunteer and I use your example, including your Tiktok handle will ensure you're tagged in the video! 
                </p>
            </MDBCol>
            <MDBCol md="6" lg="4" className="mb-4">
                <MDBTypography tag="h6" className="mb-3 text-primary">
                <MDBIcon fas icon="rocket text-primary pe-2" /> Are there any benefits to volunteering to be an example on Tiktok?
                </MDBTypography>
                <p>
                Yes! If you volunteer and I use your example, you'll get an extra deep-dive into why I chose your season for you. You'll get to see a behind-the-scenes look at comparison of colors, picking of the inspiration to go alongside it, a review of your context photos and what they tell me about your features, etc! I don't generally skip anyone who says "yes" to the front of the queue, so it won't alter your wait time, but you will have the chance to get extra information!
                </p>
            </MDBCol>
            <MDBCol md="6" lg="4" className="mb-4">
                <MDBTypography tag="h6" className="mb-3 text-primary">
                <MDBIcon fas icon="pen-alt text-primary pe-2" /> I'm having an issue verifying my email!
                </MDBTypography>
                <p>
                Make sure to check your "spam" and "junk" folders for the email containing your verification code. 
                It will come from DoNotReply@b696302f-ca8e-49ba-a00d-1d43cab92450.us1.azurecomm.net and the subject will be "Colors By Kayli Email Verification Code". If you do find the email in your junk folder, 
                make sure to mark it as "not spam" or as "safe sender" so you get the email I'll send you when your 
                results are ready! Still can't find the email? Do you have a different email address you can try using? If nothing else is working, 
                you can email lancechristianwest@gmail.com for help. 
                Include all details and errors you can in your email plus the best way to contact you (email, phone, tiktok, instagram, etc…).
                </p>
            </MDBCol>
            <MDBCol md="6" lg="4" className="mb-4">
                <MDBTypography tag="h6" className="mb-3 text-primary">
                <MDBIcon fas icon="user text-primary pe-2" /> I'm having an issue with the website!
                </MDBTypography>
                <p>
                Try clearing your browsers cookies and cache. The easist way to do this is to use an "incognito" or "private" browser window.
                Do you have a different email address you can try using? If nothing else is working, you can email lancechristianwest@gmail.com for help. 
                Include all details and errors you can in your email plus the best way to contact you (email, phone, tiktok, instagram, etc…).
                </p>
            </MDBCol>
            <MDBCol md="6" lg="4" className="mb-4">
                <MDBTypography tag="h6" className="mb-3 text-primary">
                <MDBIcon fas icon="home text-primary pe-2" /> Do you issue refunds?
                </MDBTypography>
                <p>
                <strong>
                    <u>Unfortunately no</u>.
                </strong>{" "}
                We do not issue full or partial refunds, but if you have questions, don't hesitate to reach out via email.
                </p>
            </MDBCol>
            <MDBCol md="6" lg="4" className="mb-4">
                <MDBTypography tag="h6" className="mb-3 text-primary">
                <MDBIcon fas icon="book-open text-primary pe-2" /> How can I reach you if I have other questions?
                </MDBTypography>
                <p>
                You can email me at kayli@colorsbykayli.com
                </p>
            </MDBCol>
            </MDBRow>
        </section>
        </MDBContainer>
      <Footer></Footer>
    </div>
  );
};

export default Faq;
