import { useNavigate, useParams } from 'react-router-dom';
import './PageTracker.css';

type PageTrackerProps = {
  page: number;
  setPage: any;
  pageStatuses: Boolean[];
}



const PageTracker: React.FC<PageTrackerProps> = ({ page, setPage, pageStatuses }) => {
    let navigate = useNavigate();
    let { email } = useParams();
     
    const changePage = (num: number) => {
        if(num >= 0) {
            setPage(num);
            navigate('/requests/' + email + `/view/${num}`);
        } else {
            setPage(0);
            navigate('/requests/' + email + `/view/0`);
        }
    }

    const getBackgroundColor = (num: any) => {
        if(num === page) {
            return "#9cdbd9";
        }
        if(pageStatuses[num]) {
            return "#bee492";
        } else {
            return "white";
        }
    }

  return (
    <div className="trackerContainer" >
        <div className="trackerSubContainer">
            <div className={page === 0 ? "backButtonDisabled" : "backButton"} onClick={(e) => changePage(page-1)} >
                <i className="bi bi-arrow-left-short"></i>
            </div>
            <div className="trackerPageFirst" style={{ backgroundColor: getBackgroundColor(0)}} onClick={(e) => changePage(0)}>Intro</div>
            <div className="trackerPage" style={{ backgroundColor: getBackgroundColor(1)}} onClick={(e) => changePage(1)}>Selfie</div>
            <div className="trackerPage" style={{ backgroundColor: getBackgroundColor(2)}} onClick={(e) => changePage(2)}>Pics</div>
            <div className="trackerPage" style={{ backgroundColor: getBackgroundColor(3)}} onClick={(e) => changePage(3)}>Other</div>
            <div className="trackerPage" style={{ backgroundColor: getBackgroundColor(4)}} onClick={(e) => changePage(4)}>Submit</div>
        </div>
    </div>
  );
};

export default PageTracker;
