import React, { useRef, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import { KayliColorAPI } from '../apis/KayliColorAPI';



function TestUpload() {
    const login = (code: string) =>{
        
    }

    const [selectedMainImage, setSelectedMainImage] = useState<string | undefined>(undefined);
    const inputFile = useRef(null);

    const uploadMainFile = (file: any) => {
        KayliColorAPI.createMainRequestImage("vazuso@teleg.eu", file, false)
            .then((response)=>{
                setSelectedMainImage(response.data);
            })
            .catch(function (error) {
                console.log(error.response ? error.response : error.request);
            })
      }

    const [images, setImages] = React.useState([]);
    const maxNumber = 69;

    const onChange = (imageList: any, addUpdateIndex: any) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
      };

      return (
        <div className="App">
          <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
          >
            {({
              imageList,
              onImageUpload,
              onImageRemoveAll,
              onImageUpdate,
              onImageRemove,
              isDragging,
              dragProps,
            }) => (
              // write your building UI
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Click or Drop here
                </button>
                &nbsp;
                <button onClick={onImageRemoveAll}>Remove all images</button>
                {imageList.map((image, index) => (
                  <div key={index} className="image-item">
                    <img src={image['data_url']} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button onClick={() => onImageUpdate(index)}>Update</button>
                      <button onClick={() => onImageRemove(index)}>Remove</button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </ImageUploading>
          <br/><br/><br/><br/><br/><br/>
          <img onClick={(e) => (inputFile.current as any).click()} alt="not found" width={"85px"} src={selectedMainImage || "../portraitPlaceholder.jpg"} />
          <input ref={inputFile} type="file" name="myImage"
            onChange={(event: any) => {
                console.log(event.target.files[0]); // Log the selected file
                uploadMainFile(event.target.files[0])
              }}
            ></input>
        </div>
      );
}

export default TestUpload;
