import './Loading.css';
import Spinner from 'react-bootstrap/Spinner';

type LoadingProps = {
  className?: string | undefined;
  loading: Boolean;
}

const Loading: React.FC<LoadingProps> = ({ className, loading }) => {

  return (
    <div>
      { loading && (<div className={"loadingContainer " + className} style={{ visibility: loading ? 'visible': 'hidden'}} >
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="info" />
        <Spinner animation="grow" variant="light" />
      </div>)}
    </div>
  );
};

export default Loading;
