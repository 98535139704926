import { Navigate, Outlet, useParams, useSearchParams } from 'react-router-dom'
import { RequestInitiation } from './models/RequestInitiation';
import { Ticket } from './models/Ticket';
import { KayliColorAPI } from './apis/KayliColorAPI';
import { TicketResult } from './models/TicketResult';

/*type AdminRoutesProps = {
  setTicket: any;
  setResult: any;
  ticket?: Ticket;
  result?: TicketResult;
}*/

const AdminRoutes: React.FC = () => {
  //let { email } = useParams();
  //email = email?.toLowerCase();
  let adminCode = localStorage.getItem("adminCode");

  if(!adminCode) {
    return (<Navigate to='/login'/>)
  }

  /*if(email && (!ticket || ticket?.email !== email || !result || result?.email !== email)) {
    KayliColorAPI.getTicket(email)
      .then((response)=>{
        setTicket(response.data);
      })
      .catch(function (error) {
          console.log(error.response ? error.response : error.request);
      })

    KayliColorAPI.getResult(email)
      .then((response)=>{
        setResult(response.data);
      })
      .catch(function (error) {
          console.log(error.response ? error.response : error.request);
      })
  }*/

  return (<Outlet/>)
}

type PrivateRoutesProps = {
  upsertColorRequest: any;
}

const PrivateRoutes: React.FC<PrivateRoutesProps> = ({ upsertColorRequest }) => {
  let { email } = useParams();
  email = email?.toLowerCase();

  let colorRequests = [] as RequestInitiation[];
  let requestsJson = localStorage.getItem("colorAnalysisRequests");
  if(requestsJson)
  {
    colorRequests = JSON.parse(requestsJson);
  }

  let relevantRequest = colorRequests.find((r) => { return r.email === email });

  if(!email) {
    return <Navigate to={`/`}/>
  }

  if(!relevantRequest) {
    let newRequest = {email: email!.toLowerCase(), verificationCode: 'NA', verified: false};
    upsertColorRequest(newRequest)
  }

  return <Outlet/>;
}

export { AdminRoutes, PrivateRoutes };