import './ExamplePics.css';

type ExamplePicsProps = {
  hasGoodPic: Boolean;
  picOne: string;
  picTwo: string;
  text11: string | undefined;
  text12?: string | undefined;
  text13?: string | undefined;
  text14?: string | undefined;
  text21: string | undefined;
  text22?: string | undefined;
  text23?: string | undefined;
  text24?: string | undefined;
  text25?: string | undefined;
}

const ExamplePics: React.FC<ExamplePicsProps> = ({ hasGoodPic, picOne, picTwo, text11, text12, text13, text14, text21, text22, text23, text24, text25 }) => {

  return (
    <div className="requestViewRow">
            <div className="requestViewRowTwo">
              <img className="exampleImage" alt="not found" width={"150px"} src={picOne}></img>
              <div className="textAndIcon" >
              <div className="centerIt"><i className={hasGoodPic ? "icon bi bi-check-all examplePicIcon" : "icon bi bi-x-octagon badPicIcon"}></i><br/></div>
                <div className="examplePicText">
                    {text11 && (<div>{text11}<br/></div>)}
                    {text12 && (<div>{text12}<br/></div>)}
                    {text13 && (<div>{text13}<br/></div>)}
                    {text14 && (<div>{text14}<br/></div>)}
                </div>
              </div>
            </div>

            <div className="requestViewRowTwo">
              <img className="exampleImage" alt="not found" width={"150px"} src={picTwo}></img>
              <div className="textAndIcon" >
                <div className="centerIt"><i className="icon bi bi-x-octagon badPicIcon"></i><br/></div>
                <div className="examplePicText">
                    {text21 && (<div>{text21}<br/></div>)}
                    {text22 && (<div>{text22}<br/></div>)}
                    {text23 && (<div>{text23}<br/></div>)}
                    {text24 && (<div>{text24}<br/></div>)}
                    {text25 && (<div>{text25}<br/></div>)}
                </div>
              </div>
            </div>
          </div>
  );
};

export default ExamplePics;
