import React, { useEffect, useState } from 'react';
import './Admin.css';
import { Ticket } from '../../models/Ticket';
import { useNavigate } from 'react-router-dom';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { TicketSearch } from '../../models/TicketSearch';
import { ColorAnalysisRequestState } from '../../models/ColorAnalysisRequestState';
import { TicketSearchOrderBy } from '../../models/TicketSearchOrderBy';
import DataTable from 'react-data-table-component';
import { CommentType } from '../../models/CommentType';
import { toZonedTime } from 'date-fns-tz';
import Select, { MultiValue } from 'react-select';
import { Button } from 'react-bootstrap';
import { Footer } from '../../components/Footer';
import Loading from '../../components/Loading';
import { ColorSeasons } from '../../models/ColorSeasons';
import Dropdown from 'react-dropdown';
import { Blurb } from '../../models/Blurb';
import { InspoImage, InspoImageType } from '../../models/InspoImage';



function AdminInspo() {
  let navigate = useNavigate(); 

  const seasonsForDropDown = Object.values(ColorSeasons);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [currentSeason, setCurrentSeason] = useState<ColorSeasons>(ColorSeasons.BrightSpring);

  const [allInspoImages, setAllInspoImages] = useState<InspoImage[]>([]);
  const [relevantHairInspo, setRelevantHairInspo] = useState<InspoImage[]>([]);
  const [relevantMakeupInspo, setRelevantMakeupInspo] = useState<InspoImage[]>([]);

  useEffect(() => {
    KayliColorAPI.getAllInspoImages()
      .then((response)=>{
        setAllInspoImages(response.data);
        setRelevantHairInspo(response.data.filter((i) => i.season === currentSeason && i.type === InspoImageType.Hair))
        setRelevantMakeupInspo(response.data.filter((i) => i.season === currentSeason && i.type === InspoImageType.Makeup))
        setLoading(false);
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        setErrored(true);
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  const deleteInspoImage = (url: string, type: InspoImageType) => {
    let fileNameAndExtension = "";
    if(type === InspoImageType.Hair) {
      fileNameAndExtension = url.substring(63);
    } else {
      fileNameAndExtension = url.substring(65);
    }
    KayliColorAPI.deleteInspoImage(fileNameAndExtension, type)
      .then((response)=>{
        let newRelevantInspoArray: InspoImage[] = [];
        let newAllInspoArray: InspoImage[] = [];
        if(type === InspoImageType.Hair) {
          newRelevantInspoArray.push(...relevantHairInspo.filter(i => i.url !== url));
          setRelevantHairInspo(newRelevantInspoArray);
        }
        else {
          newRelevantInspoArray.push(...relevantMakeupInspo.filter(i => i.url !== url));
          setRelevantMakeupInspo(newRelevantInspoArray);
        }
        newAllInspoArray.push(...allInspoImages.filter(i => i.url !== url));
        setAllInspoImages(newAllInspoArray);
      })
      .catch(function (error) {
          setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
          setErrored(true);
          console.log(error.response ? error.response : error.request);
      })
      .finally( () => {
          setLoading(false);
      });
  }

  const uploadInspoImage = (event: any, type: InspoImageType) => {
    let newInspos: InspoImage[] = [];

    for(var i = 0;i<event.target.files.length;i++) {
      KayliColorAPI.uploadInspoImage(currentSeason, type, event.target.files[i])
        .then((response)=>{
          let newInspo: InspoImage = {
            season: currentSeason,
            type: type,
            url: response.data
          };
          if(type === InspoImageType.Hair) {
            newInspos.push(newInspo);
            if(newInspos.length === event.target.files.length) {
              setRelevantHairInspo([...relevantHairInspo, ...newInspos]);
            }
          }
          else {
            newInspos.push(newInspo);
            if(newInspos.length === event.target.files.length) {
              setRelevantMakeupInspo([...relevantMakeupInspo, ...newInspos]);
            }
          }
          setAllInspoImages([...allInspoImages, newInspo]);
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            setErrored(true);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
    }
  }

  const setViewedSeason = (season: ColorSeasons) => {
    setCurrentSeason(season);
    setRelevantHairInspo(allInspoImages.filter((i) => i.season === season && i.type === InspoImageType.Hair))
    setRelevantMakeupInspo(allInspoImages.filter((i) => i.season === season && i.type === InspoImageType.Makeup))
  }

  return (
    <div>
        <Dropdown disabled={loading || errored} value={currentSeason} options={seasonsForDropDown} onChange={(event: any) => setViewedSeason(event.value) } />
        <h4>Hair Inspo</h4>
      <br/>
      <div className="inspoContainer">
        {relevantHairInspo && relevantHairInspo.map((b, i) => (
            <div className="inspoUnit" key={i}>
              <img className="inspoPic" key={i} alt="!!!" width={"70px"} src={b.url} />  
              <div className="centerIt">
                <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteInspoImage(b.url, b.type)}} >X</button>
              </div>
            </div>
        ))}
      </div>
      <span>Upload Hair Inspos for {currentSeason}
        <input
              type="file"
              name="myImage"
              className="contextButton"
              multiple
              disabled={loading || errored}
              onChange={(event: any) => {
                uploadInspoImage(event, InspoImageType.Hair);
              }}
            />
      </span>
      <br/>

      <br/>
      <h4>Makeup Inspo</h4>
      <br/>
      <div className="inspoContainer">
        {relevantMakeupInspo && relevantMakeupInspo.map((b, i) => (
            <div className="inspoUnit">
              <img className="inspoPic" key={i} alt="!!!" width={"70px"} src={b.url} />
              <div className="centerIt">
                <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteInspoImage(b.url, b.type)}} >X</button>
              </div>
            </div>
        ))}
      </div>
      <span>Upload Makeup Inspos for {currentSeason}
        <input
              type="file"
              name="myImage"
              className="contextButton"
              multiple
              disabled={loading || errored}
              onChange={(event: any) => {
                uploadInspoImage(event, InspoImageType.Makeup);
              }}
            />
      </span>

      <Loading loading={loading} className=""></Loading>
      { errorMessage && <h2> {errorMessage} </h2> }
      <button className="croppingButton" onClick={(e) => navigate('/admin')}>Back to Admin Screen</button>
      <Footer></Footer>
    </div>
    
    
  );
}

export default AdminInspo;
