import { ColorSeasons } from "./ColorSeasons";

enum BlurbType {
    Introduction="Introduction",
    Metals="Metals",
    SisterSeasons="SisterSeasons",
    OppositeSeasons="OppositeSeasons",
    Hair="Hair",
    Makeup="Makeup"
}

type Blurb = {
    id?: string;
    primarySeason: ColorSeasons;
    otherSeason?: ColorSeasons;
    type: BlurbType;
    subType: string;
    content: string;
};

export type { Blurb }
export { BlurbType }