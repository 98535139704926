import React, {   useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RequestInitiation } from '../../../models/RequestInitiation';
import { KayliColorAPI } from '../../../apis/KayliColorAPI';
import { Footer } from '../../../components/Footer';
import '../../home/Home.css';
import './VerifyEmail.css'
import Loading from '../../../components/Loading';

type VerifyEmailProps = {
    colorRequests: RequestInitiation[];
    upsertColorRequest: any;
}

const isValidVerificationCode = (code: string) => {
    return code.length === 8;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ colorRequests, upsertColorRequest }) => {
    let navigate = useNavigate(); 
    let { email } = useParams();
    email = email?.toLowerCase();

    const [verificationCode, setVerificationCode] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [hasResent, setHasResent] = useState(false);

    let currentRequest = colorRequests.find((r) => r.email === email)
    if(currentRequest && currentRequest.verified && currentRequest.verificationCode)
    {
        navigate('/requests/' + email + '/view');
    }

    const verify = (e : any) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');

        let newRequest = {email: email!.toLowerCase(), verificationCode: verificationCode, verified: false};

        KayliColorAPI.checkEmailVerification(newRequest)
            .then((response)=>{
                newRequest.verified = true;
                upsertColorRequest(newRequest)
                navigate('/requests/' + email + '/view');
            })
            .catch(function (error) {
                setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
                console.log(error.response ? error.response : error.request);
            })
            .finally( () => {
                setLoading(false);
            });
    }

    const resendEmail = (e: any) => {
        e.preventDefault();
        setLoading(true);
        setErrorMessage('');
        setHasResent(true);

        KayliColorAPI.sendEmailVerification(email!.toLowerCase())
            .then((response)=>{
                
            })
            .catch(function (error) {
                setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
                console.log(error.response ? error.response : error.request);
            })
            .finally( () => {
                setLoading(false);
            });
    }


  return (
    <div>
        <div className="verifyContainer">
            <div className="homeHeader">Color Analysis by Kayli</div>
            <div className="verifyBody">
                Important! We sent you an email: 
                <ul className='emailInfo'>
                    <li><b>Subject:</b> "Colors By Kayli Test Email"</li>
                    <li><b>Sender:</b> DoNotReply@b696302f-ca8e-49ba-a00d-1d43cab92450.us1.azurecomm.net</li>
                </ul>
                { hasResent && <div>
                    If you don't see the email, check your spam/junk folder. If you find it there, make sure to mark it as "not spam" or "known sender". If nothing is working, using a different email might help.
                    <br/><br/>
                </div> }
                
                <div className="centerIt didYouGetEmail">
                    <b>So, did you get the email?</b>
                    <br/>
                    {email}
                </div>
                
            </div>
            { errorMessage && <h6> {errorMessage} </h6> }
            <Loading loading={loading} ></Loading>
            <button disabled={loading} className="resendButton yesIGotEmail" onClick = {verify} >Yes, I got your email!</button>
            <button disabled={loading} className="resendButton" onClick = {resendEmail} >Resend Email</button>
        </div>
        <Footer></Footer>
    </div>
    
  );
}

//TODO: Limit how quickly emails can be sent on UI and API side

export default VerifyEmail;
