import React, { useEffect, useState } from 'react';
import './Admin.css';
import { Ticket } from '../../models/Ticket';
import { useNavigate } from 'react-router-dom';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { TicketSearch } from '../../models/TicketSearch';
import { ColorAnalysisRequestState } from '../../models/ColorAnalysisRequestState';
import { TicketSearchOrderBy } from '../../models/TicketSearchOrderBy';
import DataTable from 'react-data-table-component';
import { CommentType } from '../../models/CommentType';
import { toZonedTime } from 'date-fns-tz';
import Select, { MultiValue } from 'react-select';
import { Button } from 'react-bootstrap';
import { Footer } from '../../components/Footer';
import Loading from '../../components/Loading';
import { ColorSeasons } from '../../models/ColorSeasons';
import Dropdown from 'react-dropdown';
import { Blurb, BlurbType } from '../../models/Blurb';
import { InspoImage } from '../../models/InspoImage';



function AdminBlurb() {
  let navigate = useNavigate(); 

  const seasonsForDropDown = Object.values(ColorSeasons);
  const blurbTypesForDropDown = Object.values(BlurbType);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);
  const [currentSeason, setCurrentSeason] = useState<ColorSeasons>(ColorSeasons.BrightSpring);
  const [alternateSeason, setAlternateSeason] = useState<ColorSeasons | undefined>(ColorSeasons.BrightWinter);
  const [blurbType, setBlurbType] = useState<BlurbType>(BlurbType.Introduction);

  const [allBlurbs, setAllBlurbs] = useState<Blurb[]>([]);
  const [relevantIntroBlurbs, setRelevantIntroBlurbs] = useState<Blurb[]>([]);
  const [relevantMetalBlurbs, setRelevantMetalBlurbs] = useState<Blurb[]>([]);
  const [relevantHairBlurbs, setRelevantHairBlurbs] = useState<Blurb[]>([]);
  const [relevantMakeupBlurbs, setRelevantMakeupBlurbs] = useState<Blurb[]>([]);
  const [relevantSisterBlurbs, setRelevantSisterBlurbs] = useState<Blurb[]>([]);
  const [relevantOppositeBlurbs, setRelevantOppositeBlurbs] = useState<Blurb[]>([]);
  const [blurbText, setBlurbText] = useState("");
  const [nickName, setNickName] = useState("");

  useEffect(() => {
        setRelevantIntroBlurbs(allBlurbs.filter((i) => i.primarySeason === currentSeason && i.type === BlurbType.Introduction));
        setRelevantMetalBlurbs(allBlurbs.filter((i) => i.primarySeason === currentSeason && i.type === BlurbType.Metals));
        setRelevantHairBlurbs(allBlurbs.filter((i) => i.primarySeason === currentSeason && i.type === BlurbType.Hair));
        setRelevantMakeupBlurbs(allBlurbs.filter((i) => i.primarySeason === currentSeason && i.type === BlurbType.Makeup));
        setRelevantSisterBlurbs(allBlurbs.filter((i) => i.primarySeason === currentSeason && i.type === BlurbType.SisterSeasons).sort((a, b) => {return (a.otherSeason! > b.otherSeason! ? 1 : -1)}));
        setRelevantOppositeBlurbs(allBlurbs.filter((i) => i.primarySeason === currentSeason && i.type === BlurbType.OppositeSeasons).sort((a, b) => {return (a.otherSeason! > b.otherSeason! ? 1 : -1)}));
  }, [ allBlurbs ]);

  useEffect(() => {
    KayliColorAPI.getAllBlurbs()
      .then((response)=>{
        setAllBlurbs(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
        setErrored(true);
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  useEffect(() => {
    let currentBlurbIfExists = allBlurbs.find(b => b.primarySeason === currentSeason
      && b.otherSeason === alternateSeason
      && b.type === blurbType
      && b.subType === nickName);

    if(currentBlurbIfExists) {
      setBlurbText(currentBlurbIfExists.content);
      setNickName(currentBlurbIfExists.subType);
    }
    else {
      setBlurbText("");
      setNickName("");
    }
}, [ currentSeason, blurbType, alternateSeason ]);

  const setViewedSeason = (season: ColorSeasons) => {
    setCurrentSeason(season);
    setBlurbText("");
    setNickName("");
    setRelevantIntroBlurbs(allBlurbs.filter((i) => i.primarySeason === season && i.type === BlurbType.Introduction));
    setRelevantMetalBlurbs(allBlurbs.filter((i) => i.primarySeason === season && i.type === BlurbType.Metals));
    setRelevantHairBlurbs(allBlurbs.filter((i) => i.primarySeason === season && i.type === BlurbType.Hair));
    setRelevantMakeupBlurbs(allBlurbs.filter((i) => i.primarySeason === season && i.type === BlurbType.Makeup));
    setRelevantSisterBlurbs(allBlurbs.filter((i) => i.primarySeason === season && i.type === BlurbType.SisterSeasons).sort((a, b) => {return (a.otherSeason! > b.otherSeason! ? 1 : -1)}));
    setRelevantOppositeBlurbs(allBlurbs.filter((i) => i.primarySeason === season && i.type === BlurbType.OppositeSeasons).sort((a, b) => {return (a.otherSeason! > b.otherSeason! ? 1 : -1)}));
  }

  const isEmptyOrSpaces = (str: string | undefined) => {
    if(!str) { return true }
    return str === null || str!.match(/^ *$/) !== null;
  }

  const saveCurrent = (afterPromise?: any) => {
    if(isEmptyOrSpaces(blurbText)) {
      if(afterPromise) {
        afterPromise();
      }
      return;
    }

    let currentBlurbIfExists = allBlurbs.find(b => b.primarySeason === currentSeason
                                                && b.otherSeason === alternateSeason
                                                && b.type === blurbType
                                                && b.subType === nickName);

    setErrorMessage('');
    setLoading(true);

    if(currentBlurbIfExists) {
      let updateBlurb: Blurb = {
        content: blurbText,
        primarySeason: currentSeason,
        subType: nickName,
        type: blurbType,
        otherSeason: blurbType === BlurbType.SisterSeasons || blurbType === BlurbType.OppositeSeasons ? alternateSeason : undefined,
        id: currentBlurbIfExists.id
      };

      KayliColorAPI.updateBlurb(updateBlurb!)
        .then((response)=>{
          let newAllBlurbs: Blurb[] = allBlurbs.filter(i => i.id !== currentBlurbIfExists!.id);
          newAllBlurbs.push(updateBlurb!);
          setAllBlurbs(newAllBlurbs);
          if(afterPromise) {
            afterPromise();
          }
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            setErrored(true);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
    }
    else {
      let newBlurb: Blurb = {
        content: blurbText,
        primarySeason: currentSeason,
        subType: nickName,
        type: blurbType,
        otherSeason: blurbType === BlurbType.SisterSeasons || blurbType === BlurbType.OppositeSeasons ? alternateSeason : undefined,
      };
      KayliColorAPI.createBlurb(newBlurb)
        .then((response)=>{
          setAllBlurbs([...allBlurbs, response.data]);
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            setErrored(true);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
    }
  }

  const openBlurb = (blurb?: Blurb) => {
    saveCurrent(() => {
      if(!blurb) {
        setBlurbText("");
        setNickName("");
      }
  
      setBlurbText(blurb!.content);
      setNickName(blurb!.subType);
      setAlternateSeason(blurb?.otherSeason);
      setBlurbType(blurb!.type);
    });
  }

  const deleteBlurb = (blurb: Blurb) => {
    setErrorMessage('');
    setLoading(true);
    
    KayliColorAPI.deleteBlurb(blurb.id!)
        .then((response)=>{
            let newAllBlurbs: Blurb[] = allBlurbs.filter(i => i.id !== blurb.id);
            setAllBlurbs(newAllBlurbs);
            if(blurb.type === blurbType 
                && (blurb.otherSeason === alternateSeason || (blurb.type === BlurbType.Introduction || blurb.type === BlurbType.Metals || blurb.type === BlurbType.Makeup || blurb.type === BlurbType.Hair))
                && (blurb.subType === nickName || blurb.type !== BlurbType.Introduction) ) {
              let toOpenBlurb = newAllBlurbs.find(b => b.primarySeason = currentSeason);
              if(!toOpenBlurb) {
                setBlurbText("");
                setNickName("");
              }
          
              setBlurbText(toOpenBlurb!.content);
              setNickName(toOpenBlurb!.subType);
              setAlternateSeason(toOpenBlurb?.otherSeason);
              setBlurbType(toOpenBlurb!.type);
            }
        })
        .catch(function (error) {
            setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
            setErrored(true);
            console.log(error.response ? error.response : error.request);
        })
        .finally( () => {
            setLoading(false);
        });
  }

  return (
    <div>
        Primary Season:
        <Dropdown className="kayCoDropDown" disabled={loading || errored} value={currentSeason} options={seasonsForDropDown} 
          onChange={(event: any) => { saveCurrent(); setViewedSeason(event.value) } } />
        
        Type:
        <Dropdown className="kayCoDropDown" disabled={loading || errored} value={blurbType} options={blurbTypesForDropDown} 
          onChange={ (event: any) => { saveCurrent(); setBlurbType(event.value) } } />

        <span style={{ visibility: blurbType === BlurbType.OppositeSeasons || blurbType === BlurbType.SisterSeasons ? 'visible': 'hidden'}}>
          Sis/Oppo Season:
          <Dropdown className="kayCoDropDown" disabled={loading || errored || blurbType === BlurbType.Introduction || blurbType === BlurbType.Metals || blurbType === BlurbType.Makeup || blurbType === BlurbType.Hair} 
            value={alternateSeason} options={seasonsForDropDown} onChange={(event: any) => { saveCurrent(); setAlternateSeason(event.value) } } />
        </span>

        <span style={{ visibility: blurbType === BlurbType.Introduction ? 'visible': 'hidden'}}>
          Nick Name: <input disabled={loading || errored || blurbType !== BlurbType.Introduction} className="requestViewInput introInput" 
            onChange = {(e) => setNickName(e.target.value)} value = {nickName}></input>
        </span>

        <textarea className="textarea" value={blurbText} onChange={e => setBlurbText(e.target.value) } />
        <button className="requestViewButton basicInfoButton" 
          disabled={loading || isEmptyOrSpaces(blurbText) || (isEmptyOrSpaces(nickName) && blurbType === BlurbType.Introduction)}
          onClick={(e) => saveCurrent()} >Save</button>

        <Loading loading={loading} className=""></Loading>

        <h5>{currentSeason} Intros</h5>
        {relevantIntroBlurbs && relevantIntroBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => openBlurb(b) }>
            {b.subType}-
            <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteBlurb(b)}} >X</button>
          </div>
        ))}
        <h5>{currentSeason} Metals</h5>
        {relevantMetalBlurbs && relevantMetalBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => openBlurb(b) }>
            Metals-
            <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteBlurb(b)}} >X</button>
          </div>
        ))}
        <h5>{currentSeason} Hair</h5>
        {relevantHairBlurbs && relevantHairBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => openBlurb(b) }>
            Hair-
            <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteBlurb(b)}} >X</button>
          </div>
        ))}
        <h5>{currentSeason} Makeup</h5>
        {relevantMakeupBlurbs && relevantMakeupBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => openBlurb(b) }>
            Makeup-
            <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteBlurb(b)}} >X</button>
          </div>
        ))}
        <h5>Sister Seasons</h5>
        {relevantSisterBlurbs && relevantSisterBlurbs.map((b: Blurb, i: any) => (
          <div className="blurbThumbnail" key={i} onClick={e => openBlurb(b) }>
            {b.otherSeason}-
            <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteBlurb(b)}} >X</button>
          </div>
        ))}
        <h5>Opposite Seasons</h5>
        {relevantOppositeBlurbs && relevantOppositeBlurbs.map((b, i) => (
          <div className="blurbThumbnail" key={i} onClick={e => openBlurb(b) }>
            {b.otherSeason}-
            <button className="requestViewButton removeButton inspoRemoveButton" disabled={loading || errored} onClick={(e) => {deleteBlurb(b)}} >X</button>
          </div>
        ))}

        { errorMessage && <h2> {errorMessage} </h2> }
        <button className="croppingButton" onClick={(e) => navigate('/admin')}>Back to Admin Screen</button>
        <Footer></Footer>
    </div>
    
    
  );
}

export default AdminBlurb;
