import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base'
import Home from './pages/home/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './styles/global.css'
import Admin from './pages/admin/Admin';
import Login from './pages/login/Login';
import { AdminRoutes, PrivateRoutes } from './ProtectedRoutes';
import VerifyEmail from './pages/request/VerifyEmail/VerifyEmail';
import ViewRequest from './pages/request/View/ViewRequest';
import { RequestInitiation } from './models/RequestInitiation';
import { Ticket } from './models/Ticket';
import AdminRequestManage from './pages/admin/AdminRequestManage';
import { TicketResult } from './models/TicketResult';
import RequestPayment from './pages/request/RequestPayment';
import Results from './pages/request/Results';
import RequestInQueuePaid from './pages/request/RequestInQueuePaid';
import RequestInQueue from './pages/request/RequestInQueue';
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminBlurbs from './pages/admin/AdminBlurbs';
import AdminInspo from './pages/admin/AdminInspo';
import Faq from './pages/Faq';
import AdminQueueManagement from './pages/admin/AdminQueueManagement';
import ScrollToTop from './components/ScrollToTop';
import TestUpload from './pages/TestUpload';

registerLicense("Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfcnVQRWZfV0NxV0M=");

const App = () => {
  const [colorRequests, setColorRequests] = useState<RequestInitiation[]>([]);
  const [ticket, setTicket] = useState<Ticket>();
  const [result, setResult] = useState<TicketResult>();

  console.log('test environment variables: ' + process.env.API_URL);

  useEffect(() => {
    let requestsJson = localStorage.getItem("colorAnalysisRequests");
    if(requestsJson)
    {
      let parsedRequests = JSON.parse(requestsJson) as RequestInitiation[];
      if(parsedRequests.length > 0)
      {
        setColorRequests(parsedRequests);
      }
    }
  }, [ ]);

  function upsertColorRequest(request: RequestInitiation) {
    let existingRequest = colorRequests.find((r) => r.email === request.email)
    if(existingRequest) {
      const newList = colorRequests.map((item) => {
          if (item.email === existingRequest!.email) {
            const updatedItem: RequestInitiation = { ...request };
            return updatedItem;
          }
          return item;
      });
      setColorRequests(newList);
      localStorage.setItem("colorAnalysisRequests", JSON.stringify(newList))
    }
    else {
      setColorRequests([...colorRequests, request]);
      localStorage.setItem("colorAnalysisRequests", JSON.stringify([...colorRequests, request]))
    }
  }

  return (
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home colorRequests={colorRequests} upsertColorRequest={upsertColorRequest} />}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="/testupload" element={<TestUpload/>}/>
            <Route path="/FAQs" element={<Faq/>}/>
            <Route path="/requests/:email/verify" element={<VerifyEmail colorRequests={colorRequests} upsertColorRequest={upsertColorRequest} />}/>
            <Route path="/requests/:email" element={<PrivateRoutes upsertColorRequest={upsertColorRequest} />}>
              <Route path="/requests/:email/view" element={<ViewRequest />}/>
              <Route path="/requests/:email/view/:pageNum" element={<ViewRequest />}/>
              <Route path="/requests/:email/results" element={<Results />}/>
              <Route path="/requests/:email/payment" element={<RequestPayment />}/>
              <Route path="/requests/:email/inqueue" element={<RequestInQueue />}/>
              <Route path="/requests/:email/alreadypaid" element={<RequestInQueuePaid />}/>
            </Route>
            <Route element={<AdminRoutes />}>
              <Route path="/admin" element={<Admin/>}/>
              <Route path="/admin/blurbs" element={<AdminBlurbs/>}/>
              <Route path="/admin/inspos" element={<AdminInspo/>}/>
              <Route path="/admin/queueManagement" element={<AdminQueueManagement/>}/>
              <Route path="/admin/:email/requests" element={<AdminRequestManage />}/>
            </Route>
          </Routes>
        </ScrollToTop>
      </Router>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// <Route path="*" element={<NotFound/>}/>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
