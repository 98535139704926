import React, { useState } from 'react';
import './Login.css';
import { useNavigate } from "react-router-dom";
import { Button} from 'react-bootstrap';
import { Footer } from '../../components/Footer';



function Login() {
    let navigate = useNavigate(); 
    const navigateHome = () =>{ 
        navigate(`/`);
    }

    const login = (code: string) =>{
        // TODO: Check code is correct first via API
        localStorage.setItem("adminCode", code);
        navigate(`/admin`);
    }

    const [adminCode, setAdminCode] = useState('');

  return (
    <div>
        Admin only Login. All other users please return to homepage
        <br/>
        <Button onClick={navigateHome}>
            Return to user homepage
        </Button>
        <input type="password" onChange = {(e) => setAdminCode(e.target.value)}></input>
        <Button onClick={() => login(adminCode)}>
            Login
        </Button>
        <Footer></Footer>
    </div>
  );
}

export default Login;
