export enum ColorAnalysisRequestState {
    NewUnverified="NewUnverified",
    NewVerified="NewVerified",
    ImportedVerified="ImportedVerified",
    ImportedEmailed="ImportedEmailed",
    InQueueUnpaid="InQueueUnpaid",
    ReadyToPay="ReadyToPay",
    ReadyToPayAfterWait="ReadyToPayAfterWait",
    InQueuePaid="InQueuePaid",
    NeedMoreInformation="NeedMoreInformation",
    ResultsDelivered="ResultsDelivered",
    ResultsOpened="ResultsOpened",
    ReOpened="ReOpened", //For if they add a comment after results have been delivered
    ReResolved="ReResolved", //After reopened tickets have been resolved
    Refunded="Refunded" //For the occasional refund so we can track those
  }

  export const ColorAnalysisRequestStateGroupings = {
    ResultsReadyStatuses: function (): ColorAnalysisRequestState[] {
      return [ColorAnalysisRequestState.ResultsDelivered, ColorAnalysisRequestState.ResultsOpened, ColorAnalysisRequestState.ReResolved]
    },
    PaidStatuses: function (): ColorAnalysisRequestState[] {
      return [ColorAnalysisRequestState.ResultsDelivered, ColorAnalysisRequestState.ResultsOpened, ColorAnalysisRequestState.ReResolved,
        ColorAnalysisRequestState.InQueuePaid
      ]
    },
    TicketFilledOut: function (): ColorAnalysisRequestState[] {
      return [ ColorAnalysisRequestState.ResultsDelivered, ColorAnalysisRequestState.ResultsOpened, ColorAnalysisRequestState.ReResolved,
              ColorAnalysisRequestState.InQueuePaid, ColorAnalysisRequestState.InQueueUnpaid, ColorAnalysisRequestState.ReadyToPay,
              ColorAnalysisRequestState.ReadyToPayAfterWait ]
    },
    ReadyToPay: function (): ColorAnalysisRequestState[] {
      return [ ColorAnalysisRequestState.ReadyToPay, ColorAnalysisRequestState.ReadyToPayAfterWait ]
    },
    GetPrettyStatusExplanation: function (status: ColorAnalysisRequestState): string {
      if (status === ColorAnalysisRequestState.NewUnverified) { return "Please fill out this request info and then click submit on the final page."; }
      else if (status === ColorAnalysisRequestState.NewVerified) { return "Please fill out this request info and then click submit on the final page."; }
      else if (status === ColorAnalysisRequestState.ImportedVerified) { return "Please double check this request info, fill out anything that is missing, and then click submit."; }
      else if (status === ColorAnalysisRequestState.ImportedEmailed) { return "Please double check this request info, fill out anything that is missing, and then click submit."; }
      else if (status === ColorAnalysisRequestState.InQueueUnpaid) { return "I've got all your info and will send you an email when it's your turn!"; }
      else if (status === ColorAnalysisRequestState.ReadyToPay) { return "I've got all your info! Please make a payment and I'll help you find your color season ASAP!"; }
      else if (status === ColorAnalysisRequestState.ReadyToPayAfterWait) { return "I've got all your info! Please make a payment and I'll help you find your color season ASAP!"; }
      else if (status === ColorAnalysisRequestState.InQueuePaid) { return "You're in the queue and I will send you your color analysis soon!"; }
      else if (status === ColorAnalysisRequestState.NeedMoreInformation) { return "I need a different analysis selfie from you. Please read my comments, upload a new picture, and click submit."; }
      else if (status === ColorAnalysisRequestState.ResultsDelivered) { return "Color Analysis Results Delivered!"; }
      else if (status === ColorAnalysisRequestState.ResultsOpened) { return "Color Analysis Results Delivered!"; }
      else if (status === ColorAnalysisRequestState.ReOpened) { return "Color Analysis Results Delivered!"; } //For if they add a comment after results have been delivered
      else if (status === ColorAnalysisRequestState.ReResolved) { return "Color Analysis Results Delivered!"; } //After reopened tickets have been resolved
      else if (status === ColorAnalysisRequestState.Refunded) { return "I'm sorry there was a problem. You've been fully refunded."; } //For the occasional refund so we can track those
      return "Unknown. Something has gone wrong. I'm very sorry. Please email me."
    }
  }