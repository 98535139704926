import React, { useEffect, useState } from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import { Ticket } from '../../models/Ticket';
import { Footer } from '../../components/Footer';
import { KayliColorAPI } from '../../apis/KayliColorAPI';

const RequestInQueuePaid: React.FC = () => {
  let navigate = useNavigate(); 
  let { email } = useParams();
  email = email?.toLowerCase();
  const [ticket, setTicket] = useState<Ticket>();
  
  useEffect(() => {
    KayliColorAPI.getTicket(email!)
      .then((response)=>{
        setTicket(response.data);
      })
      .catch(function (error) {
        console.log(error.response ? error.response : error.request);
      })
  }, [ ]);

  return (
    <div>
      <div className="homeContainer">
        <div className="otherPageHeader">Results coming very soon!</div>

        <div className="homeBody">{`Thanks for the extra analysis selfie. Since you have already paid, you are back at the very front of the queue. I will email you with your results very soon!`}</div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default RequestInQueuePaid;
