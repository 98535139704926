import React, { useState } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import { RequestInitiation } from '../../models/RequestInitiation';
import { KayliColorAPI } from '../../apis/KayliColorAPI';
import { Footer } from '../../components/Footer';
import Loading from '../../components/Loading';
import { ColorAnalysisRequestState, ColorAnalysisRequestStateGroupings } from '../../models/ColorAnalysisRequestState';
import { Ticket } from '../../models/Ticket';

type HomeProps = {
    colorRequests: RequestInitiation[];
    upsertColorRequest: any;
}

const isValidEmail = (email: string) => {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(email);
}

const Home: React.FC<HomeProps> = ({ colorRequests, upsertColorRequest }) => {
    let navigate = useNavigate(); 

    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const navigateBasedOnStatus = (t: Ticket) => {
        if(t.status === ColorAnalysisRequestState.NewUnverified || t.status === ColorAnalysisRequestState.ImportedEmailed) {
            navigate('/requests/' + t.email + '/verify');
        }
        else if(ColorAnalysisRequestStateGroupings.ResultsReadyStatuses().indexOf(t.status) > -1) {
            navigate('/requests/' + t.email + '/results');
        }
        else {
            navigate('/requests/' + t.email + '/view');
        }
    } 

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setErrorMessage('');
        setLoading(true);
        
        KayliColorAPI.createTicket(email)
            .then((response)=>{
                let newRequest = {email: email!.toLowerCase(), verificationCode: "", verified: (response.data.status !== ColorAnalysisRequestState.NewUnverified && response.data.status !== ColorAnalysisRequestState.ImportedEmailed)};
                upsertColorRequest(newRequest)
                navigateBasedOnStatus(response.data);
            })
            .catch(function (error) {
                setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
                console.log(error.response ? error.response : error.request);
            })
            .finally( () => {
                setLoading(false);
            });
    }

    const resumeTicket = (email: string, verified: boolean, verificationCode?: string) => {
        setErrorMessage('');
        setLoading(true);
        
        KayliColorAPI.getTicket(email)
            .then((response)=>{
                navigateBasedOnStatus(response.data);
            })
            .catch(function (error) {
                setErrorMessage(error.response ? `${error.response.data.friendlyMessage}. ${KayliColorAPI.tryLater}` : KayliColorAPI.standardError);
                console.log(error.response ? error.response : error.request);
            })
            .finally( () => {
                setLoading(false);
            });
    }

  return (
    <div>
        <div className="homeContainer">
            { errorMessage && <h2> {errorMessage} </h2> }
            <div className="homeHeader">Color Analysis by Kayli</div>
            <div className="homeBody">I'd love to help you achieve your personal style goals by discovering your color season. Enter your email to below to get started.
            </div>
            
            <form onSubmit = {handleSubmit}>
                <input className="enterEmail" placeholder='email' onChange = {(e) => setEmail(e.target.value)} value = {email}></input>
                <br/>
                <button className="sendEmail" type='submit' disabled={!isValidEmail(email) || loading} >Go</button>
            </form>
            <Loading loading={loading} ></Loading>

            {colorRequests && colorRequests?.length > 0 && (
                <div>
                    <h5 className="subHeading">Or click your email to pick up where you left off:</h5>
                    <div className="existingList">{colorRequests.map(r => <div className="existingTicket" onClick={e => resumeTicket(r.email, r.verified, r.verificationCode)} >{r.email}</div>)}</div>
                </div>
            )}
        </div>
        <Footer></Footer>
    </div>
    
  );
}

export default Home;
