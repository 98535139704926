import { ColorSeasons } from "./ColorSeasons";

enum InspoImageType {
    Hair="Hair",
    Makeup="Makeup"
}

type InspoImage = {
    season: ColorSeasons;
    type: InspoImageType;
    url: string;
};

export type { InspoImage }
export { InspoImageType }